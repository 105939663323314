<template>
    <div v-if="prop.links.length > 3">
        <div class="d-flex flex-wrap mb-1 justify-content-end">
            <div v-for="(link, index) in prop.links" :key="index">
                <div
                    v-if="link.url === null"
                    class="mr-1 mb-1 px-2 py-1 text-sm text-gray-400 border rounded"
                    v-html="link.label"
                />
                <div
                    v-else
                    class="mr-1 mb-1 px-2 py-1 text-sm text-gray-400 border rounded"
                    :class="{ 'bg-primary text-white': link.active }"
                >
                    <inertia-link
                        :href="
                            (count && search)
                                ? link.url +
                                  `&search=${search}` +
                                  `&count=${count}`
                                : (search == null&&count)
                                ? link.url + `&count=${count}`
                                : link.url
                        "
                        v-html="link.label"
                    />
                    <!-- <inertia-link
                        :href="
                            search ? link.url + `&search=${search}` : link.url
                        "
                        v-html="link.label"
                    /> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        prop: Object,
        search: String,
        count: String
    },
};
</script>
