export default {
    // install: (Vue, options, debounce = null) => {
    //       // create a helpers object and inject methods into object
    //   Vue.config.globalProperties.$helpers = {
    //         // Debounce method
    //         debounce: (func) => {
    //           // clearTimeout is used when you want to prevent the callback from getting called before that would happen.
    //           clearTimeout(debounce);
    //           // Stores timeout id  and delays 400 millisecond
    //           debounce = setTimeout(() => {
    //             // call function
    //             func();
    //           }, 400)
    //         },

    //         // Date format method to convert date to dd/mm/yyyy format
    dateFormat: (value, dash = "/") => {
        function pad(str) {
            return str < 10 ? "0" + str : str;
        }
        var date = new Date(value);
        return value
            ? [
                  pad(date.getDate()),
                  pad(date.getMonth() + 1),
                  date.getFullYear()
              ].join(dash)
            : "";
    },
    //         checkUrlSlug: (slug) => {
    //           let url = window.location.href;
    //           var subDomain = url
    //             .replace("http://", "")
    //             .replace("https://", "")
    //             .split("/")[0]
    //             .split(".")[0];
    //           if (subDomain == slug) return true;
    //           return false;
    //         },
    numberOnly: evt => {
        var theEvent = evt || window.event;

        // Handle paste
        if (theEvent.type === "paste") {
            key = event.clipboardData.getData("text/plain");
        } else {
            // Handle key press
            var key = theEvent.key;
            var code = theEvent.which ? theEvent.which : theEvent.keyCode;
            // key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (
            !regex.test(key) && ![37,39,9,8,16,46,38,40].includes(code)
           
        ) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
            evt.target.value = "";
        }
    }
    //   }
    // }
};
